<template>
  <section class="property-summary">
    <div class="property-summary-gallery-buttons">
      <button
        v-if="displayedListing.high_quality_media"
        class="property-button photos-button"
        @click="openPropertyGallery()"
      >
        <Photos color="#FFFFFF" :width="23" />
        <span class="display-inline-block-small-only">
          {{ displayedListing.high_quality_media.length }}
        </span>
        <span class="display-inline-block-small-and-up">{{
          $t("show_photos")
        }}</span>
      </button>
      <button
        v-if="typeof displayedListing.video_url !== 'undefined'"
        class="property-button three-d-button"
        @click="openPropertyGallery(true)"
      >
        <ThreeDimensions :width="15" color="#FFFFFF" />
        <span>{{ $t("3d_visit") }}</span>
      </button>
    </div>
    <PropertyPriceDetails
      class="hidden-large"
      :is-sold="displayedListing.is_sold"
      :price="displayedListing.price"
      :surface="displayedListing.surface"
    />
    <h1 class="property-summary-title">{{ title }}</h1>
    <p class="property-summary-address">
      {{ displayedListing.address }}
      <a href="#map" v-scroll-to="'#map'" class="property-summary-map-link">
        <Pin color="#ed9473" height="20" />
        <span>{{ $t("show_on_map") }}</span>
      </a>
    </p>
    <section class="property-summary-amenities">
      <div v-if="displayedListing.bedroom_count > 0">
        <span><Bed height="15"/></span>
        {{ $tc("bedroom", displayedListing.bedroom_count) }}
      </div>
      <div v-if="displayedListing.bathroom_count > 0">
        <span><Shower height="15"/></span>
        {{ $tc("bathroom", displayedListing.bathroom_count) }}
      </div>
      <div v-if="displayedListing.wc_count > 0">
        <span><Toilet height="16"/></span>
        {{ $tc("wc", displayedListing.wc_count) }}
      </div>
      <div v-if="displayedListing.cellar_count > 0">
        <span><Cellar height="16"/></span>
        {{ $tc("number_of_cellar", displayedListing.cellar_count) }}
      </div>
      <div v-if="displayedListing.parking_count > 0">
        <span><Parking height="15"/></span>
        {{ $tc("number_of_parking", displayedListing.parking_count) }}
      </div>
      <div
        v-if="displayedListing.floor && displayedListing.floor !== 'undefined'"
      >
        <span><Floor height="17"/></span>
        {{ $t("floor") }} : {{ displayedListing.floor }}
      </div>
    </section>
  </section>
</template>

<script>
import Bed from "@/components/Icons/Bed.vue";
import Shower from "@/components/Icons/Shower.vue";
import Toilet from "@/components/Icons/Toilet.vue";
import Parking from "@/components/Icons/Parking.vue";
import Cellar from "@/components/Icons/Cellar.vue";
import Floor from "@/components/Icons/Floor.vue";
import Photos from "@/components/Icons/Photos.vue";
import Pin from "@/components/Icons/Pin.vue";
import ThreeDimensions from "@/components/Icons/ThreeDimensions.vue";

import PropertyPriceDetails from "@/components/Property/PropertyPriceDetails.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "PropertySummary",
  computed: {
    ...mapState(["displayedListing"]),
    title() {
      return (
        this.displayedListing.seo_title ||
        `${this.displayedListing.type} ${this.$tc(
          "room",
          this.displayedListing.room_count
        )} de ${this.$n(this.displayedListing.surface, "surface", "fr-FR")} m²`
      );
    }
  },
  components: {
    Toilet,
    Bed,
    Shower,
    Parking,
    Floor,
    Cellar,
    Photos,
    ThreeDimensions,
    Pin,
    PropertyPriceDetails
  },
  methods: mapActions(["openPropertyGallery"])
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.hidden-large {
  @media #{$tablet-landscape-and-up} {
    display: none;
  }
}

.property-summary-address {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: $space-3x;
}

.property-summary-map-link {
  display: block;
  color: $pink;
  text-decoration: none;
  @media #{$tablet-portrait-and-up} {
    display: inline-block;
    margin-left: $space-2x;
  }

  span {
    margin-left: $space-1x;
    text-decoration: underline;
  }

  svg {
    vertical-align: text-bottom;
  }
}

.property-summary-title:first-letter {
  text-transform: capitalize;
}
.property-summary-title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: $space-3x;
  @media #{$tablet-portrait-and-up} {
    font-size: 30px;
  }
  @media #{$tablet-portrait-and-up} {
    margin-top: $space-2x;
  }
}
.property-summary-amenities {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: $space-1x;
  div {
    margin-bottom: $space-2x;
  }
  span {
    width: 30px;
    display: inline-block;
  }

  svg {
    margin: auto;
    width: 100%;
  }
}
.property-summary-amenities > div {
  flex-basis: 50%;
  @media #{$tablet-portrait-and-up} {
    flex-basis: 33.33333%;
  }
  @media #{$tablet-landscape-and-up} {
    flex-basis: auto;
  }
}

.property-button {
  color: white;
  border: none;
  border-radius: 22px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: $space-1x $space-2x;
  cursor: pointer;

  span {
    margin-left: $space-1x;
  }
}

.property-summary-gallery-buttons {
  position: absolute;
  transform: translateY(-100%);
  top: -$space-1x;
  right: $space-2x;
  display: flex;
  justify-content: flex-end;
  @media #{$desktop-and-up} {
    right: 0;
  }
}

.photos-button {
  background-color: $black;
}

.three-d-button {
  background-color: $pink;
  margin-left: $space-1x;
}

.display-inline-block-small-only {
  display: inline-block;
  @media #{$tablet-portrait-and-up} {
    display: none;
  }
}
.display-inline-block-small-and-up {
  display: none;
  @media #{$tablet-portrait-and-up} {
    display: inline-block;
  }
}
</style>
