<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 13 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Annonce" transform="translate(-416.000000, -521.000000)">
        <g id="Pièces" transform="translate(165.000000, 521.000000)">
          <g id="Group-5" transform="translate(251.000000, 0.000000)">
            <path
              d="M5.50342,13.8975 L7.23675333,13.8975 C9.33322,13.8975 11.0821533,12.4372 11.48342,10.4975 L1.25675333,10.4975 C1.65802,12.4372 3.40695333,13.8975 5.50342,13.8975 L5.50342,13.8975 Z M2.90342,7.0975 L9.83675333,7.0975 L9.83675333,1.9975 C9.83675333,1.5283 9.44848667,1.1475 8.97008667,1.1475 L3.77008667,1.1475 C3.29168667,1.1475 2.90342,1.5283 2.90342,1.9975 L2.90342,7.0975 Z M1.17008667,9.6475 L11.5700867,9.6475 L11.5700867,7.9475 L1.17008667,7.9475 L1.17008667,9.6475 Z M4.41142,14.63445 L4.11675333,15.5975 L8.62342,15.5975 L8.32875333,14.63445 C7.97688667,14.7084 7.61115333,14.7475 7.23675333,14.7475 L5.50342,14.7475 C5.12815333,14.7475 4.76328667,14.7084 4.41142,14.63445 L4.41142,14.63445 Z M3.60108667,14.3956 C1.67015333,13.65015 0.30342,11.80565 0.30342,9.6475 L0.30342,7.0975 L2.03675333,7.0975 L2.03675333,1.9975 C2.03675333,1.0591 2.81328667,0.2975 3.77008667,0.2975 L8.97008667,0.2975 C9.92688667,0.2975 10.70342,1.0591 10.70342,1.9975 L10.70342,7.0975 L12.4367533,7.0975 L12.4367533,9.6475 C12.4367533,11.80565 11.07002,13.65015 9.13908667,14.3956 L9.83675333,16.4475 L2.90342,16.4475 L3.60108667,14.3956 Z"
              id="Fill-1"
              :fill="color"
            ></path>
            <path
              d="M5.50342,13.8975 L7.23675333,13.8975 C9.33322,13.8975 11.0821533,12.4372 11.48342,10.4975 L1.25675333,10.4975 C1.65802,12.4372 3.40695333,13.8975 5.50342,13.8975 Z M2.90342,7.0975 L9.83675333,7.0975 L9.83675333,1.9975 C9.83675333,1.5283 9.44848667,1.1475 8.97008667,1.1475 L3.77008667,1.1475 C3.29168667,1.1475 2.90342,1.5283 2.90342,1.9975 L2.90342,7.0975 Z M1.17008667,9.6475 L11.5700867,9.6475 L11.5700867,7.9475 L1.17008667,7.9475 L1.17008667,9.6475 Z M4.41142,14.63445 L4.11675333,15.5975 L8.62342,15.5975 L8.32875333,14.63445 C7.97688667,14.7084 7.61115333,14.7475 7.23675333,14.7475 L5.50342,14.7475 C5.12815333,14.7475 4.76328667,14.7084 4.41142,14.63445 Z M3.60108667,14.3956 C1.67015333,13.65015 0.30342,11.80565 0.30342,9.6475 L0.30342,7.0975 L2.03675333,7.0975 L2.03675333,1.9975 C2.03675333,1.0591 2.81328667,0.2975 3.77008667,0.2975 L8.97008667,0.2975 C9.92688667,0.2975 10.70342,1.0591 10.70342,1.9975 L10.70342,7.0975 L12.4367533,7.0975 L12.4367533,9.6475 C12.4367533,11.80565 11.07002,13.65015 9.13908667,14.3956 L9.83675333,16.4475 L2.90342,16.4475 L3.60108667,14.3956 Z"
              id="Stroke-3"
              :stroke="color"
              stroke-width="0.595"
              stroke-linejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Toilets"
};
</script>
